import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
  Tooltip,
  Chip,
  createFilterOptions,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import RoutePath from 'app/router/RoutePath';
import NavigateAwayWarningDialog from 'common/components/navigateAwayWarningDialog';
import useCallbackPrompt from 'common/components/useCallbackPrompt';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { onKeywordChange, getSuggestion } from 'features/search/state/service';
import { resetTINSuggestion } from 'features/search/state/reducer';
import { getStates } from 'features/common/state/service';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import store from 'app/store';
import * as Constants from 'common/Constants';
import { EVALTYPE, FILE_UPLOAD_PROPS, DEFAULT_PAGE_SIZE, PROVIDER_PAGE_SIZE } from 'common/Constants';
import AccessDeniedDialog from 'common/components/AccessDeniedDialog';
import { clearInlinePageError } from 'app/state/reducer';
import DataUsabilityRatingLink from 'common/components/DataUsabilityRatingLink';
import CustomRating from 'common/components/CustomRating';
import FormAutocompleteTextField from 'common/components/FormAutocompleteTextField';
import EvaluationName from './EvaluationName';
import { save, getMsaSuggestions, getAllCustomizedWeights, saveWeightsFile, getNetworkRating } from '../state/service';
import InputHeader from './InputHeader';
import EvaluationAction from './EvaluationAction';
import FileUploadDialog from '../../../common/components/FileUploadDialog';

const useStyles = makeStyles(() => ({
  blurText: {
    color: 'transparent',
    textShadow: '0 0 8px #000',
  },
}));

const CTA_MAP = {
  NPI: 'Select NPI(s) to compare',
  TAXID: 'Select Provider(s) to compare',
};

const PAGE_HEADER_MAP = {
  MARKET: 'Create Market Study',
  TAXID: 'Create Provider Study',
};

const SUGG_FILTER_MAP = {
  NPI: 'NPI',
  TAXID: 'TAX-ID',
};

export default function CreateEvaluation({ studyType }) {
  const navigate = useNavigate();
  const styles = useStyles();

  const location = useLocation();
  const evaluation = useSelector((state) => state.evaluation.evaluation);
  const networks = useSelector((state) => state.provider.networks);
  const stateOptions = useSelector((state) => state.provider.states);
  const suggestion = useSelector((state) => state.search.suggestion);
  const suggestionLoading = useSelector((state) => state.search.suggestionLoading);
  const allMsaSuggestions = useSelector((state) => state.evaluation.msaSuggestions);
  const allCustomizedWeights = useSelector((state) => state.evaluation.customizedWeights);
  const accessDeniedFlag = useSelector((state) => state.evaluation.accessDeniedFlag);
  const planTypeOptions = useSelector((state) => state.provider.planTypes);
  const outlierOptions = [{ label: 'Exclude', value: '0' }, { label: 'Include', value: '1' }];
  const { id, createdAt, updatedAt, createdBy, updatedBy, privateEvaluation, name, status,
    ...cloneEvaluation } = evaluation;
  const [cloneEval] = useState(location.state?.isCLone ? cloneEvaluation : {});
  const { control, formState, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {
      ...cloneEval,
      keyword: '',
      state: null,
      networkCodePT: 'ALL',
      compareNetworksPT: 'ALL',
      outlier: outlierOptions[0],
      npiType: Constants.NPI_TYPES[parseInt(cloneEval?.npiType, 10) - 1],
    },
    mode: 'onBlur',
  });

  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [currentNetworkOptions, setCurrentNetworkOptions] = useState([]);
  const [evalType] = useState(studyType && studyType === 'market' ? EVALTYPE.MARKET : EVALTYPE.PROVIDER);
  const [initialIds, setInitialIds] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [block, setBlock] = useState(true);
  const [networkWithRating, setNetworkWithRating] = useState([]);
  const blockNavigation = formState.isDirty && block;
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(blockNavigation);

  const watchState = watch('state');
  const watchMsa = watch('msa');
  const watchMainNetwork = watch('networkCode');
  // const watchNpiSelection = watch('npis');
  const watchEinsSelection = watch('eins');
  const watchComparedNetworks = watch('compareNetworks');

  const mainNetworkPT = watch('networkCodePT');
  const compareNetworksPT = watch('compareNetworksPT');
  const pageSize = (evalType === EVALTYPE.PROVIDER) ? PROVIDER_PAGE_SIZE : DEFAULT_PAGE_SIZE;

  const saveAndProcess = (data) => {
    const compareNetworks = data.compareNetworks.map((nw) => ({ networkCode: nw.networkCode }));
    let npiValues = null;
    switch (evalType) {
      case EVALTYPE.PROVIDER:
        npiValues = data.eins.map((ein) => ({
          npi: ein.searchValue,
          npiLabel: ein.value.replace(ein.searchValue, '').trim(),
        }));
        break;
      case EVALTYPE.MARKET:
        npiValues = [{ npi: data.msa?.code }];
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`Unexpected eval type: ${evalType}`);
        break;
    }
    if (npiValues !== null) {
      store.dispatch(
        save({
          ...data,
          npis: npiValues,
          compareNetworks,
          state: evalType === 'MARKET' ? null : data.state?.code,
          evalType,
          weight: null,
          outlier: data.outlier.value,
          npiType: data.npiType?.value,
        }),
      ).unwrap().then(() => {
        setBlock(false);
        confirmNavigation();
        navigate(RoutePath.app.evaluation.path);
      });
    }
  };

  const handleNetworkChange = (newValue) => {
    const selectedIds = {};
    if (newValue && Array.isArray(newValue)) {
      newValue.forEach((item) => {
        selectedIds[item.planGroupAlias] = item;
      });
    }
  };

  const handleLocaleChange = (stateCodes) => {
    if (!stateCodes?.length) {
      return;
    }
    const filteredNetworkList = networks.filter((nw) => (nw.allState
      || nw.states.some((state) => stateCodes.includes(state))));
    if (evalType === EVALTYPE.MARKET) {
      const ratedNetworkList = filteredNetworkList.map((elem) => {
        const match = networkWithRating?.find((r) => r.network === elem.code);
        return match ? { ...elem, rating: match.rating } : elem;
      });
      setCurrentNetworkOptions(ratedNetworkList);
    } else {
      setCurrentNetworkOptions(filteredNetworkList);
    }
  };

  const handleUploadDialogOpen = () => {
    store.dispatch(clearInlinePageError());
    setUploadDialogOpen(true);
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.label}`,
  });

  const shouldDisableStateRelated = useMemo(() => {
    if (evalType === EVALTYPE.MARKET) {
      return !watchMsa;
    }
    return !watchState;
  }, [watchState, watchMsa, evalType]);

  const mainNetworkOptions = useMemo(() => {
    if (mainNetworkPT === 'ALL' || !mainNetworkPT) return [...currentNetworkOptions];
    return currentNetworkOptions.filter((nw) => nw.planType === mainNetworkPT);
  }, [currentNetworkOptions, mainNetworkPT]);

  const targetNetworkOptions = useMemo(() => (
    currentNetworkOptions.filter((opt) => opt.code !== watchMainNetwork
      && ((compareNetworksPT === 'ALL' || !compareNetworksPT) ? true : compareNetworksPT === opt.planType))
  ), [currentNetworkOptions, watchMainNetwork, compareNetworksPT]);

  const enableEinsSelection = useMemo(() => (
    !(watchEinsSelection && Array.isArray(watchEinsSelection) && watchEinsSelection.length > 9)
  ), [watchEinsSelection]);

  const enableComparedNetworkSelection = useMemo(() => (
    !(watchComparedNetworks && Array.isArray(watchComparedNetworks)
      && watchComparedNetworks.length > 3)
  ), [watchComparedNetworks]);

  const fetchSuggestionData = debounce((e) => {
    store.dispatch(
      getSuggestion({
        keyword: e.target.value,
        state: getValues('state')?.code,
        type: SUGG_FILTER_MAP[evalType],
        pageSize,
      }),
    );
  }, 250);

  const renderNetworkOptions = (props, opt) => (
    evalType === EVALTYPE.MARKET ? (
      <Grid {...props} xs={12} container alignItems="center" justifyItems="center">
        <Grid item xs={7}>
          <Typography>{opt.label}</Typography>
        </Grid>
        {opt.rating !== null
        && (
          <Grid item xs={5}>
            <CustomRating
              ratingProps={{
                readOnly: true,
                value: opt.rating,
              }}
            />
          </Grid>
        )}
      </Grid>
    ) : (
      <Typography {...props}>
        {opt.label}
      </Typography>
    )
  );

  useEffect(() => {
    getNetworkRating(watchMsa?.code).then((data) => {
      setNetworkWithRating(data);
    });
  }, [watchMsa]);

  useEffect(() => {
    if (networkWithRating && watchMsa) {
      handleLocaleChange(watchMsa.states);
    }
  }, [networkWithRating, watchMsa]);

  useEffect(() => {
    let { outlier } = cloneEval;
    if (!outlier) outlier = 0;
    setValue('outlier', outlierOptions[outlier]);

    if (cloneEval?.evalType === EVALTYPE.PROVIDER) {
      const { npis, state } = cloneEval;
      const stateMatch = stateOptions.find((opt) => opt.code === state);
      setValue('state', stateMatch, { shouldDirty: true });
      handleLocaleChange([state]);
      const npiValue = npis.map(
        (dto) => ({ value: dto.npiLabel ? `${dto.npi} ${dto.npiLabel}` : dto.npi, type: 'NPI', searchValue: dto.npi }),
      );
      setInitialIds(npiValue);
      setValue('eins', initialIds, { shouldDirty: true });
    }
    if (cloneEval?.evalType === EVALTYPE.MARKET) {
      const msaMatch = allMsaSuggestions.find((msa) => msa.code === cloneEval.npis[0].npi);
      setValue('msa', msaMatch, { shouldDirty: true });
      handleLocaleChange(msaMatch?.states);
    }
  }, [cloneEval]);

  useEffect(() => {
    if (initialIds.length > 0 && evalType === EVALTYPE.PROVIDER) {
      setValue('eins', initialIds, { shouldDirty: true });
    }
  }, [initialIds]);

  useEffect(() => {
    const initialSearchValues = {};
    initialIds.forEach((initialId) => {
      initialSearchValues[initialId.searchValue] = initialId.searchValue;
    });
    const options = suggestion.reduce((finalOptions, opt) => {
      if (!initialSearchValues[opt.searchValue]) {
        finalOptions.push({ ...opt });
      }
      return finalOptions;
    }, []);
    setSuggestionOptions(options);
  }, [suggestion]);

  useEffect(() => {
    store.dispatch(getStates());
    store.dispatch(getMsaSuggestions());
    store.dispatch(getAllCustomizedWeights());
    store.dispatch(resetTINSuggestion());
    store.dispatch(clearInlinePageError());
  }, []);

  return (
    <Grid container spacing={2} marginLeft={-0.5}>
      <Grid item xs={12} sx={{ paddingTop: '0px' }}>
        <Typography variant="h1" className={styles.paddingMajorQuestion}>
          {PAGE_HEADER_MAP[evalType]}
        </Typography>
      </Grid>
      <InputHeader title="What networks are you interested in?" />
      {evalType === EVALTYPE.MARKET && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              First, select a Metropolitan Statistical Area (MSA) to get started.
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Controller
              name="msa"
              control={control}
              rules={{
                required: THIS_FIELD_IS_REQUIRED,
              }}
              defaultValue={null}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  id="msa"
                  {...field}
                  onChange={(_, data) => {
                    field.onChange(data);
                  }}
                  disableClearable
                  fullWidth
                  isOptionEqualToValue={(opt, val) => {
                    const code = val.code || val;
                    // eslint-disable-next-line eqeqeq
                    return opt.code == code;
                  }}
                  options={allMsaSuggestions}
                  filterOptions={filterOptions}
                  renderInput={(inProps) => (
                    <TextField
                      {...inProps}
                      id="msa-input"
                      label="MSA"
                      required
                      error={!!error}
                      helperText={error?.message || ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </>
      )}
      {evalType !== EVALTYPE.MARKET && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              First, select a state to get started.
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Controller
              name="state"
              control={control}
              rules={{ required: THIS_FIELD_IS_REQUIRED }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  id="stateFilter"
                  {...field}
                  onChange={(_e, option) => {
                    handleLocaleChange([option.code]);
                    field.onChange(option);
                  }}
                  fullWidth
                  isOptionEqualToValue={(opt, val) => opt.label === val.label}
                  className={styles.filterInput}
                  disableClearable
                  options={stateOptions}
                  renderInput={(inProps) => (
                    <TextField
                      {...inProps}
                      label="State"
                      required
                      error={error}
                      helperText={error ? error.message : null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" paddingTop="1rem">
          Please select a single network to be compared with others.
        </Typography>
        {evalType === EVALTYPE.MARKET
        && (
          <>
            <Typography variant="body2" paddingTop="0.75rem">
              Networks now include our new Data Usability Rating.
            </Typography>
            <Grid paddingTop="0.75rem" paddingBottom="0.75rem">
              <DataUsabilityRatingLink />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2} xs={6}>
        <Grid item xs={3} md={2}>
          <Controller
            name="networkCodePT"
            control={control}
            render={({ field }) => (
              <Tooltip title="Select a Plan Type" placement="top">
                <Autocomplete
                  id="networkCodePTFilter"
                  {...field}
                  fullWidth
                  disabled={shouldDisableStateRelated}
                  onChange={(_, data) => {
                    field.onChange(data);
                  }}
                  options={planTypeOptions}
                  renderInput={(inProps) => (
                    <TextField
                      {...inProps}
                      label="Plan Type"
                    />
                  )}
                />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={9} md={10}>
          <Controller
            name="networkCode"
            rules={{
              required: THIS_FIELD_IS_REQUIRED,
            }}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                id="input-network"
                disabled={shouldDisableStateRelated}
                options={mainNetworkOptions}
                getOptionLabel={(option) => option.label ?? ''}
                onChange={(event, newInputValue) => {
                  onChange(newInputValue ? newInputValue.code : '');
                }}
                value={networks.find((option) => option.code === value) || null}
                filterSelectedOptions
                filterOptions={filterOptions}
                getOptionSelected={(option, t) => option.code === t}
                renderOption={(props, option) => renderNetworkOptions(props, option)}
                renderInput={(params) => (
                  <TextField
                    id="main-network-input"
                    {...params}
                    label="Main Network"
                    required
                    error={!shouldDisableStateRelated && error}
                    helperText={!shouldDisableStateRelated && error ? error.message : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" paddingTop="1rem">To compare with</Typography>
      </Grid>
      <Grid item container spacing={2} xs={6}>
        <Grid item xs={3} md={2}>
          <Controller
            name="compareNetworksPT"
            control={control}
            render={({ field }) => (
              <Tooltip title="Select a Plan Type" placement="top">
                <Autocomplete
                  id="compareNetworksFilter"
                  {...field}
                  fullWidth
                  disabled={shouldDisableStateRelated}
                  onChange={(_, data) => {
                    field.onChange(data);
                  }}
                  options={planTypeOptions}
                  renderInput={(inProps) => (
                    <TextField
                      {...inProps}
                      label="Plan Type"
                    />
                  )}
                />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={9} md={10}>
          <Controller
            name="compareNetworks"
            rules={{
              required: THIS_FIELD_IS_REQUIRED,
            }}
            control={control}
            defaultValue={[]}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                id="input-targetNetworks"
                disabled={shouldDisableStateRelated}
                options={targetNetworkOptions}
                value={networks.filter((option) =>
                  value.some((obj) => obj.networkCode === option.code))}
                getOptionLabel={(option) => option.label}
                getOptionDisabled={() => !enableComparedNetworkSelection}
                filterOptions={filterOptions}
                defaultValue={[]}
                onChange={(event, newInputValue) => {
                  handleNetworkChange(newInputValue);
                  const selectedCodes = newInputValue.map((option) => ({
                    networkCode: option.code,
                  }));
                  onChange(selectedCodes);
                }}
                filterSelectedOptions
                isOptionEqualToValue={(option, val) => option.code === val.code}
                renderOption={(props, option) => renderNetworkOptions(props, option)}
                renderInput={(params) => (
                  <TextField
                    id="network-compared-input"
                    {...params}
                    label="Select one or more networks"
                    required
                    error={!shouldDisableStateRelated && error}
                    helperText={!shouldDisableStateRelated && error ? error.message : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      {
        (evalType in CTA_MAP)
        && (<InputHeader title={CTA_MAP[evalType]} />)
      }
      {['NPI', 'TAXID'].includes(evalType) && (
        <Grid container item xs={6}>
            {evalType === EVALTYPE.PROVIDER && (
              <Controller
                name="eins"
                rules={{
                  required: THIS_FIELD_IS_REQUIRED,
                }}
                control={control}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    fullWidth
                    id="input-eins"
                    disabled={shouldDisableStateRelated}
                    options={[...suggestionOptions]}
                    filterOptions={(options) => options}
                    filterSelectedOptions
                    getOptionDisabled={() => !enableEinsSelection}
                    getOptionLabel={(option) => option.value ?? ''}
                    isOptionEqualToValue={(opt, val) => opt.searchValue === val.searchValue}
                    defaultValue={[]}
                    onChange={(_e, data) => {
                      field.onChange(data);
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        className={
                          suggestionLoading
                            ? `${styles.blurText} ${props.className}`
                            : props.className
                        }
                        key={option.searchValue}>
                        &nbsp;
                        {option.value}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="npi-input"
                        label="Enter Tax ID or Partial Provider Name"
                        onChange={(e) => {
                          store.dispatch(onKeywordChange());
                          fetchSuggestionData(e);
                        }}
                        required
                        error={!shouldDisableStateRelated && error}
                        helperText={!shouldDisableStateRelated && error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      Array.isArray(value) && (value.map((option, index) => (
                        <Tooltip title={option.value} key={option.value}>
                          <Chip
                            variant="contained"
                            label={option.value === option.searchValue ? option.value : option.value.replace(option.searchValue, '').trim()}
                            {...getTagProps({ index })}
                          />
                        </Tooltip>
                      )))
                    }
                  />
                )}
              />
            )}
        </Grid>
      )}
      {evalType !== EVALTYPE.MARKET && (
        <>
          <InputHeader title="Optional: NPI Entity Type" />
          <Grid item xl={2} lg={3} md={4} sm={6} xs={8}>
            <FormAutocompleteTextField
              fieldName="npiType"
              formControl={control}
              autocompleteProps={{
                id: 'npiTypeDropdown',
                options: Constants.NPI_TYPES,
                isOptionEqualToValue: (option, value) => option.value === value.value,
              }}
              textFieldProps={{
                label: 'NPI Type',
              }}
            />
          </Grid>
        </>
      )}
      <InputHeader title="Optional: Include Outlier Rates" />
      <Grid item container xl={2} lg={3} md={4} sm={6} xs={8}>
        <Controller
          name="outlier"
          control={control}
          render={({ field }) => (
            <Autocomplete
              id="outlierDropDown"
              fullWidth
              {...field}
              onChange={(_, data) => {
                field.onChange(data);
              }}
              options={outlierOptions}
              isOptionEqualToValue={(option, value) => option.label === value?.label}
              disableClearable
              renderInput={(inProps) => (
                <TextField
                  {...inProps}
                  id="outlierTxt"
                  className={styles.inputTextField}
                  label="Outlier"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <InputHeader title="Optional: Customize your weights" />
      <Grid item xs={12}>
        <Typography variant="body1">
          Select your customized weights.
          Otherwise, we will use our default weights.
        </Typography>
      </Grid>
      <Grid container item xs={6}>
        <Controller
          name="weightId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              id="weightsName"
              fullWidth
              {...field}
              options={allCustomizedWeights}
              onChange={(_, data) => {
                field.onChange(data.id);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              disableClearable
              value={allCustomizedWeights.find(
                (option) => option.id === field.value,
              ) || null}
              renderInput={(params) => (
                <TextField
                  id="weights-input"
                  {...params}
                  label="Customized Weights"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <Button
                          size="small"
                          onClick={() => handleUploadDialogOpen()}
                        >
                          Upload Weights
                        </Button>
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <EvaluationName styles={styles} control={control} />
      <EvaluationAction onClick={handleSubmit(saveAndProcess)} isPopUpOpen={uploadDialogOpen} />
      <FileUploadDialog
        open={uploadDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        dialogDisplayInfo={FILE_UPLOAD_PROPS.customWeights}
        saveFileReduxFunction={saveWeightsFile}
      />
      <NavigateAwayWarningDialog
        open={(showPrompt && blockNavigation)}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        content={{ title: Constants.NAVIGATION_AWAY_WARNING_TITLE_MSG,
          body: Constants.NAVIGATION_AWAY_WARNING_BODY_MSG,
          confirm: Constants.NAVIGATION_AWAY_CONFIRM_BUTTON,
          cancel: Constants.NAVIGATION_AWAY_CANCEL_BUTTON }}
      />
      <AccessDeniedDialog
        open={accessDeniedFlag}
      />
    </Grid>
  );
}
