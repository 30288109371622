import { Grid, Autocomplete, TextField, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_PLAN_BY_STATE, PR_STATE, VI_STATE } from 'common/Constants';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import store from 'app/store';
import { getStates } from 'features/common/state/service';
import { getStateNetworkRating } from 'features/evaluation/state/service';
import { HeaderRow, HeaderRowRightAlign } from 'features/common/Style';
import TableRow from './tableRow';
import { setPrevSelectedState } from '../state/reducer';

export default function ViewPlansByStateTable() {
  const stateOptions = useSelector((state) => state.provider.states);
  const [filteredStateOptions, setFilteredStateOptions] = useState([]);
  const [stateNetworkWithRating, setStateNetworkWithRating] = useState([]);
  const [viewStateData, setViewStateData] = useState([]);
  const prevSelectedState = useSelector((state) => state.home.prevSelectedState);
  const statesToExclude = [PR_STATE, VI_STATE];

  useEffect(() => {
    store.dispatch(getStates());
    if (prevSelectedState !== null) {
      getStateNetworkRating(prevSelectedState?.code).then((res) => {
        setStateNetworkWithRating(res);
      });
    }
  }, []);

  useEffect(() => {
    setViewStateData(stateNetworkWithRating);
  }, [stateNetworkWithRating]);

  useEffect(() => {
    if (stateOptions != null && Array.isArray(stateOptions) && stateOptions.length > 0) {
      setFilteredStateOptions(stateOptions.filter((item) => item?.code
        && !statesToExclude.includes(item?.code)));
    }
  }, [stateOptions]);

  return (
    <>
      <Grid item xs={9}>
        <Autocomplete
          id="stateSelector"
          size="small"
          disableClearable
          fullWidth
          onChange={(_, data) => {
            getStateNetworkRating(data?.code).then((res) => {
              setStateNetworkWithRating(res);
              store.dispatch(setPrevSelectedState(data));
            });
          }}
          isOptionEqualToValue={(opt, val) => opt.label === val.label}
          options={filteredStateOptions}
          value={prevSelectedState}
          renderInput={(inProps) => (
            <TextField
              placeholder="Select State"
              {...inProps}
              label="View State"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <Grid
            container
            sx={{ alignItems: 'center', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', paddingTop: '1rem' }}
          >
            <Grid item xs={4.5}>
              <HeaderRow>Plan</HeaderRow>
            </Grid>
            <Grid item xs={1.5}>
              <HeaderRow>Plan Type</HeaderRow>
            </Grid>
            <Grid item xs={2}>
              <HeaderRowRightAlign sx={{ paddingRight: '1.2rem' }}>Min MSA Rating</HeaderRowRightAlign>
            </Grid>
            <Grid item xs={2}>
              <HeaderRow>Max MSA Rating</HeaderRow>
            </Grid>
            <Grid item xs={2}>
              <HeaderRow>Data Usability Rating</HeaderRow>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="horizontal"
              sx={{
                height: '0.25rem',
                borderBottomWidth: '2px',
                borderBottomColor: DIVIDER_SOLID,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {viewStateData.filter((n) => n !== null && n !== undefined).map((n, idx) => (
              <TableRow
                key={n.label}
                networkRowData={n}
                index={idx}
                dataLength={viewStateData.length}
                selectedTab={VIEW_PLAN_BY_STATE}
              />
            ))}
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}
