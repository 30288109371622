import { Grid, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_ALL_PLAN } from 'common/Constants';
import { useSelector } from 'react-redux';
import React from 'react';
import { HeaderRow, HeaderRowRightAlign } from 'features/common/Style';
import TableRow from './tableRow';

export default function ViewAllPlansTable() {
  const networks = useSelector((state) => state.provider.networks);
  const sortedNetworks = [...networks];
  sortedNetworks.sort((a, b) => (a.label.localeCompare(b.label)));

  return (

    <Grid item xs={12}>
      <Stack>
        <Grid
          container
          sx={{ alignItems: 'center', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap' }}
        >
          <Grid item xs={6}>
            <HeaderRow>Plan</HeaderRow>
          </Grid>
          <Grid item xs={2}>
            <HeaderRow>Plan Type</HeaderRow>
          </Grid>
          <Grid item xs={2}>
            <HeaderRow>Level</HeaderRow>
          </Grid>
          <Grid item xs={2}>
            <HeaderRowRightAlign>Hospital Count</HeaderRowRightAlign>
          </Grid>
        </Grid>
        <Grid item>
          <Divider
            orientation="horizontal"
            sx={{
              height: '0.25rem',
              borderBottomWidth: '2px',
              borderBottomColor: DIVIDER_SOLID,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {Array.isArray(sortedNetworks) && sortedNetworks.map((n, idx) => (
            <TableRow
              key={n.network}
              networkRowData={n}
              index={idx}
              dataLength={networks.length}
              selectedTab={VIEW_ALL_PLAN}
            />
          ))}
        </Grid>
      </Stack>
    </Grid>
  );
}
