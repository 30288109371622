import { Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CustomRating from 'common/components/CustomRating';
import React from 'react';
import HorizontalRatingBar from 'common/components/HorizontalRatingBar';
import { VIEW_PLAN_BY_MSA, VIEW_PLAN_BY_STATE, PLAN_BY_STATE_MIN_MSA_RATING, PLAN_BY_STATE_MAX_MSA_RATING, VIEW_ALL_PLAN } from 'common/Constants';

const RowData = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '2.875rem',
}));

export default function TableRow({ networkRowData, index, dataLength, selectedTab }) {
  return (
    <>
      {selectedTab === VIEW_PLAN_BY_MSA && (
        <RowData container sx={{ height: '2.5rem' }}>
          <Grid item xs={6}>
            <Typography fontSize="0.875rem">{networkRowData.networkLabel}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontSize="0.875rem">{networkRowData.planType}</Typography>
          </Grid>
          <Grid item xs={3}>
            <CustomRating
              ratingProps={{
                readOnly: true,
                value: networkRowData.rating,
              }}
            />
          </Grid>
        </RowData>
      )}
      {selectedTab === VIEW_ALL_PLAN && (
        <RowData container sx={{ height: '2.5rem' }}>
          <Grid item xs={6}>
            <Typography fontSize="0.875rem">{networkRowData.label}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize="0.875rem">{networkRowData.planType}</Typography>
          </Grid>
          <Grid item xs={2}>
            {networkRowData.allState === true
              && <Typography fontSize="0.875rem">National</Typography>
            }
            {networkRowData.allState === false && networkRowData.states.length > 1
              && <Typography fontSize="0.875rem">Multi-state</Typography>
            }
            {networkRowData.allState === false && networkRowData.states.length === 1
              && <Typography fontSize="0.875rem">{networkRowData.states[0]}</Typography>
            }
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize="0.875rem" textAlign="right">{networkRowData.hospitalCount}</Typography>
          </Grid>
        </RowData>
      )}
      {selectedTab === VIEW_PLAN_BY_STATE && (
        <RowData container sx={{ height: '2.5rem' }}>
          <Grid item xs={4.3}>
            <Typography fontSize="0.875rem">{networkRowData.label}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography fontSize="0.875rem" marginLeft="11px">{networkRowData.planType}</Typography>
          </Grid>
          <Grid item xs={2} container flexDirection="column-reverse" alignItems="flex-end">
            <Typography fontSize="0.875rem">
              {networkRowData?.minMsaScore >= 0
                && (
                  <HorizontalRatingBar
                    rating={networkRowData.minMsaScore}
                    source={PLAN_BY_STATE_MIN_MSA_RATING}
                  />
                )}
            </Typography>
          </Grid>
          <Grid item xs={0.2}>
            <Divider
              orientation="vertical"
              sx={{
                height: '2rem',
                marginRight: '0.3rem',
                marginLeft: '0.2rem',
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize="0.875rem">
              {networkRowData?.maxMsaScore >= 0
                && (
                  <HorizontalRatingBar
                    rating={networkRowData.maxMsaScore}
                    source={PLAN_BY_STATE_MAX_MSA_RATING}
                  />
                )}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CustomRating
              ratingProps={{
                readOnly: true,
                value: networkRowData.stateScore,
              }}
            />
          </Grid>
        </RowData>
      )}
      {index !== dataLength && (
        <Divider
          orientation="horizontal"
          sx={{
            borderBottomWidth: '1px',
          }}
        />
      )}
    </>
  );
}
