import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';

const ArrowDropUpStyledIcon = styled(ArrowDropUpIcon)({
  '.Mui-active &.MuiTableSortLabel-iconDirectionDesc': {
    opacity: '0.5!important',
  },
});

const ArrowDropDownStyledIcon = styled(ArrowDropDownIcon)({
  '.Mui-active &.MuiTableSortLabel-iconDirectionAsc': {
    opacity: '0.5!important',
  },
});

function SortIndicator(props) {
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'Center',
        alignItems: 'Center',
      }}
  >
      <ArrowDropUpStyledIcon viewBox="0 -8.5 24 24" {...props} />
      <ArrowDropDownStyledIcon viewBox="0 8.5 24 24" {...props} />
    </span>
  );
}
export default SortIndicator;
