/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createTheme } from '@mui/material/styles';
import RoutePath from 'app/router/RoutePath';
import { ReactComponent as SearchRatesSvgIcon } from '../asset/list-search.svg';
import { ReactComponent as IntelligenceSvgIcon } from '../asset/device-analytics.svg';
import { ReactComponent as MailSvgIcon } from '../asset/mail-icon.svg';
import { openCustomerService } from './util/commonUtil';
import SupportCardContent from './components/SupportCardContent';

export const PRIMARY_DARK = '#002D74';

export const PRIMARY = '#1976D2';

export const PRIMARY_WARNING = '#ff1744';

export const PRIMARY_LIGHT = '#AECFEF';

export const PRIMARY_DEEP_DARK = '#002D74';

export const CHECK_GREEN = '#00BE5F';

export const DIRECTION_DESC = 'DESC';

export const DIRECTION_ASC = 'ASC';

export const COLOR_PROCESSING = '#F2DF00';

export const TEXT_BLACK = '#222222';

export const PRIMARY_GRAY = '#999999';

export const DARK_GRAY = '#D0D3DB';

export const HOME_PAGE_TITLE_DARK = '#002E74';

export const DIVIDER_SOLID = '#929CA5';

export const PLUS_MINUS_DARK = '#212121';

export const PLUS_MINUS_GREEN = '#C4EBDD';

export const PLUS_MINUS_RED = '#FDF3D7';

export const MAX_KEYWORD_LENGTH = 5000;

export const LONG_KEYWORD_ERR_MSG = 'Too many parameters in your search criteria. Please refine your search.';

export const RESPONSE_STATUS_SUCCESS = 'success';

export const LIGHTER_GRAY_200 = '#E6EAEF';

export const LIGHTER_GRAY_300 = '#C7D0DB';

export const PRIMARY_RED = '#eb4034';

export const NAVIGATION_AWAY_WARNING_TITLE_MSG = 'Are you sure you want to cancel?';

export const NAVIGATION_AWAY_WARNING_BODY_MSG = 'You will lose any selections or data entered.';

export const NAVIGATION_AWAY_CONFIRM_BUTTON = 'Yes, cancel';

export const NAVIGATION_AWAY_CANCEL_BUTTON = 'Remain on page';

export const COLOR_OUTLINE_GREY = '#6A7585';

export const blueTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_LIGHT,
      dark: PRIMARY_LIGHT,
    },
  },
});

export const EVALUATION_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
};

export const REQUIRED_FILE_STATUS = {
  CREATED: { code: 'CREATED', displayName: 'Created' },
  IN_PROGRESS: { code: 'IN_PROGRESS', displayName: 'In Progress' },
  COMPLETED: { code: 'COMPLETED', displayName: 'Completed' },
  ERROR: { code: 'ERROR', displayName: 'Error' },
};

export const TRACK_VISIT_TYPE = {
  EVALUATION: 'EVALUATION_LIST',
  DOWNLOAD: 'FILE_DOWNLOAD_LIST',
  SEARCH: 'SEARCH',
  WEIGHT: 'WEIGHT_LIST',
  HOME_SEARCH: 'HOME_SEARCH',
  HOME_PROVIDER_STUDY: 'HOME_PROVIDER_STUDY',
  HOME_MARKET_STUDY: 'HOME_MARKET_STUDY',
  HOME_POPULATION_STUDY: 'HOME_POPULATION_STUDY',
  HOME_SERVICE_CASE: 'HOME_SERVICE_CASE',
  MARKET_STUDY_RERUN: 'MARKET_STUDY_RERUN',
  MARKET_STUDY_CLONE: 'MARKET_STUDY_CLONE',
  PROVIDER_STUDY_RERUN: 'PROVIDER_STUDY_RERUN',
  PROVIDER_STUDY_CLONE: 'PROVIDER_STUDY_CLONE',
  POPULATION_STUDY_RERUN: 'POPULATION_STUDY_RERUN',
  POPULATION_STUDY_CLONE: 'POPULATION_STUDY_CLONE',
};

export const PERMISSION = {
  ADMIN: 'admin',
  CREATE_INTELLIGENCE: 'CREATE_INTELLIGENCE',
  POPULATION_STUDY: 'POPULATION_STUDY',
  DOWNLOAD_SEARCH: 'DOWNLOAD_SEARCH',
  VIEW_INTELLIGENCE: 'VIEW_INTELLIGENCE',
  VIEW_OPTIMIZE: 'VIEW_OPTIMIZE',
  VIEW_SEARCH: 'VIEW_SEARCH',
};

export const BILLING_CLASS = {
  INSTITUTIONAL: 'INSTITUTIONAL',
  PROFESSIONAL: 'PROFESSIONAL',
};

export const HOME_PAGE_CARD_MAP = [
  {
    key: 'provider',
    title: 'Learn About Providers',
    content: 'Identify cost-effective providers to optimize care or find optimal network partners.',
    buttonText: 'create provider study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createProviderStudy.path,
    permission: PERMISSION.CREATE_INTELLIGENCE,
  },
  {
    key: 'market',
    title: 'Learn About Markets',
    content: 'Gain market insights to better understand your position or your competition.',
    buttonText: 'create market study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createMarketStudy.path,
    permission: PERMISSION.CREATE_INTELLIGENCE,
  },
  {
    key: 'population',
    title: 'Learn About Your Population',
    content: 'Identify cost-effective providers based on your member population.',
    buttonText: 'create population study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
    permission: PERMISSION.POPULATION_STUDY,
  },
  {
    key: 'search',
    title: 'Learn About Rates',
    content: 'Research market rates by payor/plan, billing codes and providers.',
    buttonText: 'search rates',
    icon: SearchRatesSvgIcon,
    link: RoutePath.app.search.path,
    permission: PERMISSION.VIEW_SEARCH,
  },
  {
    key: 'servicecase',
    title: 'Get help with PlanOptix',
    content: <SupportCardContent />,
    buttonText: 'create service case',
    icon: MailSvgIcon,
    onClickHandler: openCustomerService,
  },
];

export const PAGE_NAME = {
  HOME: 'home',
  SEARCH: 'search',
  EVALUATION: 'evaluation',
  CREATE_PROVIDER_STUDY: 'create-provider-study',
  CREATE_MARKET_STUDY: 'create-market-study',
  EXPORT: 'export',
  WEIGHTS: 'weights',
  ADMIN: 'admin',
};

export const OUTLIER_INDICATOR_DISPLAY = {
  '-1': 'Low',
  0: 'No',
  1: 'High',
};
// change me later if NPI is added back
export const EVALTYPE_MAP = {
  TAXID: 'Provider',
  MARKET: 'Market',
  NPI: 'Provider',
  POPULATION: 'Population',
};

export const EVALTYPE = {
  MARKET: 'MARKET',
  PROVIDER: 'TAXID',
  POPULATION: 'POPULATION',
};

export const EVALTYPE_ROUTE_MAP = {
  TAXID: RoutePath.app.evaluation.subpage.createProviderStudy.path,
  MARKET: RoutePath.app.evaluation.subpage.createMarketStudy.path,
  POPULATION: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
};

export const FILE_UPLOAD_PROPS = {
  customWeights: {
    download: 'sample_customized_weight.csv',
    href: '/sample_customized_weight.csv',
    dialogTitle: 'Custom Weights',
    textFieldLabel: 'Weights Name',
    hintText: 'customized weights',
    maxFileSize: 5,
  },
  census: {
    download: 'sample_population_census_summary.csv',
    href: '/sample_population_census_summary.csv',
    dialogTitle: 'Population Census Summary',
    textFieldLabel: 'Census Summary Name',
    hintText: 'population census summary',
    maxFileSize: 5,
  },
};

export const SHOW_ALL_RESULT = 'All';

export const CATEGORY = 'category';

export const PERCENT_DIFF = 'percentDiff';

export const TABULAR_VIEWS = (data = {}) => ({
  census: {
    tableTitle: 'Census Summary Files',
    endPoint: '/census/list',
    defaultSortField: '',
    studiesListEndPoint: '/census/evaluation-list/',
  },
  weights: {
    studiesListEndPoint: '/weights/custom-weight/evaluations/',
  },
  censusDetail: {
    tableTitle: 'Census Summary',
    endPoint: `/census/details/${data.id}`,
    defaultSortField: '',
  },
});
export const CENSUS_DELETE_CONFIRMATION_MSG = 'Are you sure you want to delete the census summary file with name';

export const PROVIDER_PAGE_SIZE = 30;
export const DEFAULT_PAGE_SIZE = 15;
export const NPI_TYPES = [
  { label: '1 - Individual', value: 1 },
  { label: '2 - Organization', value: 2 },
];
export const VIEW_PLAN_BY_MSA = 'viewPlansByMSA';
export const VIEW_PLAN_BY_STATE = 'viewPlansByState';
export const VIEW_ALL_PLAN = 'viewAllPlans';
export const POPULATION_NETWORK_RESULT = 'populationNetworkResult';
export const PLAN_BY_STATE_MIN_MSA_RATING = 'minMSARating';
export const PLAN_BY_STATE_MAX_MSA_RATING = 'maxMSARating';
export const PR_STATE = 'PR';
export const VI_STATE = 'VI';
