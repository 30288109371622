import store from 'app/store';
import { PRIMARY } from 'common/Constants';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FORMAT_BIG_NUMBER, convertDatetime } from 'common/util/commonUtil';
import { styled } from '@mui/system';
import { getBannerInfo } from '../state/service';

const Title = styled('h2')(() => ({
  fontSize: '1.75rem',
  fontWeight: '500',
  color: 'white',
  marginBlockStart: '0rem',
  marginBlockEnd: '0rem',
}));

const WelcomeText = styled('h1')(() => ({
  fontSize: '1.75rem',
  fontWeight: '500',
  color: 'white',
  marginBlockStart: '0rem',
  marginBlockEnd: '0rem',
}));

const Stat = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0rem',
  alignItems: 'center',
}));

const Banner = styled('div')(() => ({
  backgroundColor: PRIMARY,
  color: 'white',
  borderRadius: '0.5rem',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
  justifyContent: 'space-between',
  padding: '1rem',
  marginBlockEnd: '2rem',
}));

const SectionTitle = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
}));

const SectionStat = styled('div')(() => ({
  display: 'flex',
  gap: '2rem',
  alignItems: 'center',
}));

const SectionMeta = styled('div')(() => ({
  fontSize: '0.9rem',
}));

const SectionName = styled('div')(() => ({
  color: 'white',
}));

export default function HomeBanner() {
  const summaryInfo = useSelector((state) => state.home.summaryInfo);

  useEffect(() => {
    store.dispatch(getBannerInfo());
  }, []);

  return (
    <Banner>
      <SectionTitle>
        <WelcomeText>Welcome to PlanOptix</WelcomeText>
        <SectionMeta>
          Data refreshed on
          {convertDatetime(summaryInfo.refreshDate)}
        </SectionMeta>
      </SectionTitle>
      <SectionStat>
        <Stat>
          <Title>{summaryInfo.msaCount}</Title>
          <SectionName>Markets</SectionName>
        </Stat>
        <Stat>
          <Title>{summaryInfo.planCount}</Title>
          <SectionName>Networks</SectionName>
        </Stat>
        <Stat>
          <Title>
            {summaryInfo.livesCovered && `${Math.round(summaryInfo.livesCovered * 100)}%`}
          </Title>
          <SectionName>% Lives</SectionName>
        </Stat>
        <Stat>
          <Title>
            {FORMAT_BIG_NUMBER(summaryInfo.recordsCount)}
          </Title>
          <SectionName>Records</SectionName>
        </Stat>
      </SectionStat>
    </Banner>
  );
}
