import { Grid, Autocomplete, TextField, Divider, Stack } from '@mui/material';
import { DIVIDER_SOLID, VIEW_PLAN_BY_MSA } from 'common/Constants';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import store from 'app/store';
import { getAllMsa } from 'features/common/state/service';
import { getMsaSuggestions, getNetworkRating } from 'features/evaluation/state/service';
import { HeaderRow } from 'features/common/Style';
import TableRow from './tableRow';
import { setPrevSelectedMSA } from '../state/reducer';

export default function ViewPlansByMSATable() {
  const msaOptions = useSelector((state) => state.provider.msaOptions);
  const networks = useSelector((state) => state.provider.networks);
  const prevSelectedMSA = useSelector((state) => state.home.prevSelectedMSA);
  const allMsaSuggestions = useSelector((state) => state.evaluation.msaSuggestions);
  const [networkWithRating, setNetworkWithRating] = useState([]);
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  const [viewMSAData, setViewMSAData] = useState([]);

  const fetchNetworkWithRating = (msaNo) => {
    getNetworkRating(msaNo).then((res) => {
      const selectedMSA = allMsaSuggestions?.find((msa) => msa.code === msaNo);
      const filteredNetworkList = networks.filter((nw) => (nw.allState
        || nw.states.some((state) => selectedMSA?.states.includes(state))));
      setFilteredNetworks(filteredNetworkList);
      setNetworkWithRating(res);
    });
  };

  useEffect(() => {
    store.dispatch(getAllMsa());
    store.dispatch(getMsaSuggestions());
  }, []);

  useEffect(() => {
    if (prevSelectedMSA !== null) {
      fetchNetworkWithRating(prevSelectedMSA?.msaNo);
    }
  }, []);

  // get the sorted merged list after for plan type and rated network
  useEffect(() => {
    const mergedNetworks = networkWithRating.map((elem) => {
      const match = filteredNetworks.find((r) => r.code === elem.network);
      return match && { ...elem, networkLabel: match.label, planType: match.planType };
    });
    mergedNetworks.sort((a, b) => a.networkLabel.localeCompare(b.networkLabel));
    setViewMSAData(mergedNetworks);
  }, [networkWithRating]);

  return (
    <>
      <Grid item xs={9}>
        <Autocomplete
          id="msaSelector"
          size="small"
          disableClearable
          fullWidth
          options={msaOptions.filter((item) => item?.msaName && item?.msaName !== '')}
          value={prevSelectedMSA}
          onChange={(_, data) => {
            fetchNetworkWithRating(data?.msaNo);
            store.dispatch(setPrevSelectedMSA(data));
          }}
          getOptionLabel={(option) => {
            if ('msaName' in option) {
              if (typeof option.msaName === 'string' && option.msaName.endsWith(' MSA')) {
                return option.msaName.slice(0, -4);
              }
              return option.msaName;
            }
            return null;
          }}
          isOptionEqualToValue={(opt, val) => opt.msaNo === val.msaNo}
          renderInput={(props) => (
            <TextField
              {...props}
              label="View MSA"
              placeholder="Select MSA"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack>
          <Grid
            container
            sx={{ alignItems: 'center', flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', paddingTop: '1rem' }}
          >
            <Grid item xs={6}>
              <HeaderRow>Plan</HeaderRow>
            </Grid>
            <Grid item xs={3}>
              <HeaderRow>Plan Type</HeaderRow>
            </Grid>
            <Grid item xs={3}>
              <HeaderRow>Data Usability Rating</HeaderRow>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="horizontal"
              sx={{
                height: '0.25rem',
                borderBottomWidth: '2px',
                borderBottomColor: DIVIDER_SOLID,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {viewMSAData.length > 0
            && viewMSAData.filter((n) => n !== null && n !== undefined).map((n, idx) => (
              <TableRow
                key={n.network}
                networkRowData={n}
                index={idx}
                dataLength={viewMSAData.length}
                selectedTab={VIEW_PLAN_BY_MSA}
              />
            ))}
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}
