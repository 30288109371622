import { styled } from '@mui/material/styles';
import { HOME_PAGE_TITLE_DARK } from 'common/Constants';

export const HeaderRow = styled('div')(() => ({
  fontSize: '1rem',
  color: HOME_PAGE_TITLE_DARK,
  fontWeight: 600,
}));

export const HeaderRowRightAlign = styled('div')(() => ({
  fontSize: '1rem',
  color: HOME_PAGE_TITLE_DARK,
  fontWeight: 600,
  textAlign: 'right',
}));
